import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['expanceColumn']

  connect() {
    if (window.innerWidth >= 1280) {
      this.expanceColumnTargets.forEach(target => {
        this.toggleExpanceColumn({ currentTarget: target })
      })
    }
  }

  toggleExpanceColumn(event) {
    const title = event.currentTarget
    const content = title.nextElementSibling
    const state = title.dataset.pricingTableValue

    const isCollapsed = state === 'collapsed'
    const toggleTo = isCollapsed ? 'expanded' : 'collapsed'

    const contentHeight = content.scrollHeight
    const transitionSpeed = (contentHeight * 1).toFixed(2)

    title.classList.toggle('expanded')

    content.style.transition = `max-height ${transitionSpeed}ms ease-out, opacity ${transitionSpeed}ms ease-out`
    content.style.maxHeight = isCollapsed ? `${contentHeight}px` : '0px'

    title.dataset.pricingTableValue = toggleTo
  }
}
